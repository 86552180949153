<template>
  <LoginComponent />
</template>

<script>
import LoginComponent from "../components/Usuario/Login";

export default {
  name: "LoginPage",

  components: {
    LoginComponent,
  },
};
</script>
